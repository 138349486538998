import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

const NotFound = () => (
  <div className={styles.container}>
    <div className={styles.inner}>
      <h2>お探しのページは見つかりませんでした。</h2>
      <p>お探しのページは一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。</p>
      <div className={styles.link_wrapper}>
        <Link to="/">
          <i className="fas fa-angle-double-left"></i>トップページに戻る
        </Link>
      </div>
    </div>
  </div>
);

export default NotFound;
