import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import styles from './HamburgerMenu.module.css';
import NavItem from './NavItem';
import Backdrop from '../Backdrop/Backdrop';

/**
 * ハンバーガーメニューコンポーネント
 * クリックでメニューの開閉を制御するハンバーガーメニューを描画する
 * @component
 * @param {object} props
 * @param {boolean} props.isAdmin - 管理者かどうか
 * @param {boolean} props.isBaseURL - ベースURLかどうか
 * @param {function} props.handleLogout - ログアウト用の関数
 * @returns {JSX.Element}
 */
const HamburgerMenu = ({ isAdmin, isBaseURL, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const siteId = window.location.pathname.split('/')[2];
  const pathname = window.location.pathname.split('/')[1];

  /**
   * メニューの開閉を切り替える関数
   */
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.hamburger_menu}>
      {/* PCの場合 */}
      <MediaQuery query="(min-width: 1024px)">
        <div className={`${styles.hamburger} ${isOpen ? styles.open : ''}`} onClick={toggleMenu}>
          <i className="fas fa-sliders-h"></i>
          <div>データ・設定</div>
          <i className="fas fa-caret-down"></i>
        </div>
      </MediaQuery>

      {/* スマホの場合 */}
      <MediaQuery query="(max-width: 1023px)">
        <Backdrop show={isOpen} zIndex="400" />
        <div className={`${styles.hamburger} ${isOpen ? styles.open : ''}`} onClick={toggleMenu}>
          <i className="fas fa-sliders-h"></i>
        </div>
      </MediaQuery>

      {/* メニューの内容 */}
      <nav className={`${styles.menu} ${isOpen ? styles.menu_open : ''}`}>
        {/* スマホの場合 */}
        <MediaQuery query="(max-width: 1023px)">
          <div className={styles.close_btn_area} onClick={toggleMenu}>
            <i className="fas fa-times"></i>
          </div>
        </MediaQuery>

        <ul>
          <NavItem
            name={'測定データ'}
            destination={`/measurement-screen/${siteId}`}
            pathname={pathname}
            target={'measurement-screen'}
            isBaseURL={isBaseURL}
            onClick={toggleMenu}
          />

          {/* 測定データリストはadminの場合のみ表示する */}
          {isAdmin ? (
            <NavItem
              name={'測定データリスト'}
              destination={`/check-data/${siteId}`}
              pathname={pathname}
              target={'check-data'}
              isBaseURL={isBaseURL}
              onClick={toggleMenu}
            />
          ) : null}

          {/* <NavItem
            name={'日報・月報'}
            destination={`/measurement-screen/${siteId}`}
            pathname={pathname}
            target={''}
            isBaseURL={isBaseURL}
            onClick={toggleMenu}
          /> */}
          <NavItem
            name={'ダウンロード'}
            destination={`/download/${siteId}`}
            pathname={pathname}
            target={'download'}
            isBaseURL={isBaseURL}
            onClick={toggleMenu}
          />
          <NavItem
            name={'選択表示設定'}
            destination={`/selective-display-setting/${siteId}`}
            pathname={pathname}
            target={'selective-display-setting'}
            isBaseURL={isBaseURL}
            onClick={toggleMenu}
          />
          <NavItem
            name={'アラート設定'}
            destination={`/alert-setting/${siteId}`}
            pathname={pathname}
            target={'alert-setting'}
            isBaseURL={isBaseURL}
            onClick={toggleMenu}
          />

          {/* ログアウト */}
          <li>
            <Link
              to={isBaseURL ? '/' : `/login?redirect=/measurement-screen/${siteId}`}
              className={styles.logout}
              onClick={handleLogout}
            >
              ログアウト<i className="fas fa-sign-out-alt"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default React.memo(HamburgerMenu);
