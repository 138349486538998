import React from 'react';
import styles from './Header.module.css';

/**
 * 現場名を表示するコンポーネント
 * @component
 * @param {object} props
 * @param {string|undefined} props.name - 現場名
 * @param {string|undefined} props.isBaseURL - アドミンの管理画面かどうか
 * @returns {JSX.Element|null}
 */
const SiteName = ({ name, isBaseURL }) => {
  if (isBaseURL || !name) {
    return null;
  }
  return (
    <div className={styles.site_name}>
      <i className="fas fa-map-marker-alt"></i>
      <div>{name}</div>
    </div>
  );
};

export default React.memo(SiteName);
