import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AsyncComponent from './asyncComponent';
import UnauthenticatedRoute from './unauthenticatedRoute';
import AuthenticatedRoute from './authenticatedRoute';
import AppliedRoute from './appliedRoute';
import Prepare from '../components/Layout/Prepare';
import NotFound from '../components/NotFound/NotFound';
import PrintPage from '../containers/pages/PrintPage/PrintPage';

const AsyncMeasurementScreenPage = AsyncComponent(() => {
  return import('../containers/pages/MeasurementScreenPage/MeasurementScreenPage');
});

const AsyncTopPage = AsyncComponent(() => {
  return import('../containers/pages/TopPage/TopPage');
});

const AsyncCheckingDataPage = AsyncComponent(() => {
  return import('../containers/pages/CheckingDataPage/CheckingDataPage');
});

const AsyncDownloadPage = AsyncComponent(() => {
  return import('../containers/pages/DownloadPage/DownloadPage');
});

const AsyncLoginPage = AsyncComponent(() => {
  return import('../containers/pages/LoginPage/LoginPage');
});

const AsyncAdminLoginPage = AsyncComponent(() => {
  return import('../containers/pages/AdminLoginPage/AdminLoginPage');
});

const AsyncAlertSettingPage = AsyncComponent(() => {
  return import('../containers/pages/AlertSettingPage/AlertSettingPage');
});

const AsyncAlertHistoryPage = AsyncComponent(() => {
  return import('../containers/pages/AlertHistoryPage/AlertHistoryPage');
});

const AsyncSelectiveDisplaySettingPage = AsyncComponent(() => {
  return import('../containers/pages/SelectiveDisplaySettingPage/SelectiveDisplaySettingPage');
});

const AsyncPublicPage = AsyncComponent(() => {
  return import('../containers/pages/PublicPage/PublicPage');
});

const AppRoutes = (props) => (
  <Prepare>
    <Routes>
      <Route path="/" element={<AuthenticatedRoute component={AsyncTopPage} props={props} />} />
      <Route
        path="/measurement-screen/:siteId"
        element={<AuthenticatedRoute component={AsyncMeasurementScreenPage} props={props} />}
      />
      <Route
        path="/check-data/:siteId"
        element={<AuthenticatedRoute component={AsyncCheckingDataPage} props={props} />}
      />
      <Route path="/download/:siteId" element={<AuthenticatedRoute component={AsyncDownloadPage} props={props} />} />
      <Route
        path="/alert-history/:siteId"
        element={<AuthenticatedRoute component={AsyncAlertHistoryPage} props={props} />}
      />
      <Route
        path="/alert-setting/:siteId"
        element={<AuthenticatedRoute component={AsyncAlertSettingPage} props={props} />}
      />
      <Route
        path="/selective-display-setting/:siteId"
        element={<AuthenticatedRoute component={AsyncSelectiveDisplaySettingPage} props={props} />}
      />
      <Route path="/print/:siteId" element={<AuthenticatedRoute component={PrintPage} props={props} />} />
      <Route
        path="/admin"
        element={<UnauthenticatedRoute path="/admin" component={AsyncAdminLoginPage} props={props} />}
      />
      <Route path="/login" element={<UnauthenticatedRoute path="/login" component={AsyncLoginPage} props={props} />} />
      <Route path="/page/:id" element={<AppliedRoute component={AsyncPublicPage} props={props} />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  </Prepare>
);

export default AppRoutes;
