import React from 'react';
import styles from './Header.module.css';
import MediaQuery from 'react-responsive';
import queryString from 'query-string';
import HeaderItems from './HeaderItems';

/**
 * Headerを描画するコンポーネント
 * @param {object} props
 * @param {number} props.unreadNotificationNum
 * @param {string|undefined} props.siteName
 * @param {boolean} props.is_admin
 * @param {boolean} props.is_authenticated
 * @param {boolean} props.is_root
 * @param {object} props.siteAlertStatus
 * @param {function} props.handleLogout
 * @param {function} props.setDefaultState
 * @returns {JSX.Element|null}
 */
const Header = (props) => {
  if (!props.is_authenticated) {
    return null;
  }

  const isBaseURL = props.is_root;
  let siteId = window.location.pathname.split('/')[2];

  if (!siteId) {
    siteId = queryString.parse(window.location.search).site;
  }

  // 現場で今現在エラーが起きているかどうかの値を保持
  let inError = false;

  const siteAlertStatus = props.siteAlertStatus;
  const keys = Object.keys(siteAlertStatus);
  if (keys.length) {
    const errorCodes = [];
    keys.forEach((key) => {
      errorCodes.push(siteAlertStatus[key].errorCode);
    });
    if (errorCodes.includes(1)) {
      inError = true;
    }
  }

  /**
   * Header内のアイテムを描画する
   * @param {string} viewMode - スマホ or PC
   * @returns {JSX.Element}
   */
  const renderHeaderItems = (viewMode) => {
    return (
      <HeaderItems
        viewMode={viewMode}
        siteId={siteId}
        isAdmin={props.is_admin}
        isBaseURL={isBaseURL}
        siteName={props.siteName}
        unreadNotificationNum={props.unreadNotificationNum}
        inError={inError}
        handleLogout={props.handleLogout}
        setDefaultState={props.setDefaultState}
      />
    );
  };

  return (
    <div>
      <header className={styles.header}>
        {/* スマホ対応 */}
        <MediaQuery query="(max-width: 1023px)">{renderHeaderItems('smartPhone')}</MediaQuery>

        {/* PC */}
        <MediaQuery query="(min-width: 1024px)">{renderHeaderItems('pc')}</MediaQuery>
      </header>
    </div>
  );
};

export default Header;
