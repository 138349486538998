import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HamburgerMenu.module.css';

/**
 * ナビゲーションのアイテムを描画する
 * @component
 * @param {object} props
 * @param {string} props.name - アイテムの名前
 * @param {string} props.destination - linkの遷移先
 * @param {string} props.pathname - パス名
 * @param {string} props.target - activeクラス付与判定に使う
 * @param {boolean} props.isBaseURL - ベースURLかどうか
 * @param {function} props.onClick - toggle操作用の関数
 * @returns {JSX.Element|null}
 */
const NavItem = ({ name, destination, pathname, target, isBaseURL, onClick }) => {
  if (isBaseURL) {
    return null;
  }

  return (
    <li>
      <Link to={destination} className={pathname === target ? styles.active : ''} onClick={onClick}>
        {name}
      </Link>
    </li>
  );
};

export default NavItem;
