import { API_URL, cognito, zipStrage } from '../config';

export const amplifyAPIEndpointName = 'api-stack-endpoint';

export const amplifyConfig = {
  Auth: {
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    identityPoolId: cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: cognito.APP_CLIENT_ID,
    mandatorySignIn: false, // 未認証アクセスを許可
  },
  API: {
    endpoints: [
      {
        name: amplifyAPIEndpointName,
        endpoint: API_URL,
        region: 'ap-northeast-1',
      },
    ],
  },
  Storage: zipStrage,
};
