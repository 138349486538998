import styles from './SubscriptionDisconnectedModal.module.css';

/**
 * AppSyncのサブスクリプションエラーを検知した時に表示するモーダル
 * @component
 * @returns {JSX.Element}
 */
const SubscriptionDisconnectedModal = () => {
  return (
    <div>
      <div className={styles.modal_message_area}>
        <i className="fas fa-exclamation-circle"></i>
        <p>
          サーバーとの通信が切断されました。
          <br />
          ブラウザを再読み込みします
        </p>
        <br />
      </div>
      <div>
        <button
          className={styles.modal_close_btn}
          onClick={() => {
            window.location.reload();
          }}
        >
          はい
        </button>
      </div>
    </div>
  );
};

export default SubscriptionDisconnectedModal;
