import gql from 'graphql-tag';

const getLatestAlertHistoryQuery = gql`
  query getLatestAlertHistory($id: String!, $timeStamp: String!) {
    getLatestAlertHistory(id: $id, timeStamp: $timeStamp) {
      items {
        id
        errorCode
      }
    }
  }
`;

const getUnreadAlertHistoryQuery = gql`
  query getUnreadAlertHistory($siteId: String!) {
    getUnreadAlertHistory(siteId: $siteId) {
      items {
        id
      }
    }
  }
`;

const subscriptionQuery = gql`
  subscription alertSub($siteId: String!) {
    onCreateAlertHistory(siteId: $siteId) {
      id
      errorCode
      value
    }
  }
`;

export { getLatestAlertHistoryQuery, getUnreadAlertHistoryQuery, subscriptionQuery };
