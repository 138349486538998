import { Link } from 'react-router-dom';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu';
import SiteName from './SiteName';
import styles from './Header.module.css';

/**
 * Header内のアイテムを描画するコンポーネント
 * @param {object} props
 * @param {string} props.viewMode - スマホ or PC
 * @param {string} props.siteId - 現場ID
 * @param {boolean} props.isAdmin - 管理者かどうか
 * @param {boolean} props.isBaseURL
 * @param {string|undefined} props.siteName
 * @param {number} props.unreadNotificationNum
 * @param {boolean} props.inError
 * @param {function} props.handleLogout
 * @param {function} props.setDefaultState
 * @returns {JSX.Element}
 */
const HeaderItems = ({
  viewMode,
  siteId,
  isAdmin,
  isBaseURL,
  siteName,
  unreadNotificationNum,
  inError,
  handleLogout,
  setDefaultState,
}) => {
  return (
    <>
      {viewMode === 'smartPhone' && (
        <Link to="/" onClick={setDefaultState} className={styles.link_logo}>
          <img className={styles.logo} src="../images/header_logo.png" alt="logo" />
        </Link>
      )}
      <HamburgerMenu isAdmin={isAdmin} isBaseURL={isBaseURL} handleLogout={handleLogout} />
      {viewMode === 'pc' && <SiteName name={siteName} isBaseURL={isBaseURL} />}
      {isBaseURL ? null : (
        <div className={styles.alert}>
          {unreadNotificationNum ? <div className={styles.badge}>{unreadNotificationNum}</div> : null}
          <Link to={`/alert-history/${siteId}`}>
            <i className={`fas fa-bell ${inError ? styles.error : ''}`}></i>
            {viewMode === 'pc' && <div>アラート</div>}
          </Link>
        </div>
      )}
    </>
  );
};

export default HeaderItems;
