import { API } from 'aws-amplify';
import { amplifyAPIEndpointName } from './amplifyConfig';
import { IAMAuthenticationAPIEndPoints } from '../store/actions/apiEndPoints';

/**
 * pathを取得する関数
 * @param {string} path - ベースのパス
 * @param {string|object} data
 * @returns {string}
 */
const getPath = (path, data) => {
  if (!data) {
    return path;
  }

  if (typeof data === 'object') {
    path += '?';
    let keys = Object.keys(data);
    keys.forEach((key) => {
      path += `${key}=${data[key]}&`;
    });
    path = path.slice(0, -1);
  } else {
    path = `${path}/${data}`;
  }

  return path;
};

/**
 * getリクエスト用のオプションを取得する
 * @param {string} path
 * @param {string} jwtToken
 * @returns {object}
 */
const getOptions = (path, jwtToken) => {
  // 未認証ユーザーでもアクセスできるAPIgatewayのエンドポイントはIAM認証にしている
  // そこへリクエストを送る場合、ヘッダーにjwtTokenはセットしない
  // もしセットするとエラーになる
  if (IAMAuthenticationAPIEndPoints.includes(path)) {
    return {};
  }

  return {
    headers: {
      Authorization: jwtToken,
    },
  };
};

/**
 * APIgatewayにGetリクエストを送る
 * @param {string} path - ベースのパス
 * @param {string|object} data
 * @param {string} jwtToken
 * @returns {object} - APIgatewayからのレスポンス
 */
export const Get = async (path, data, jwtToken) => {
  try {
    const options = getOptions(path, jwtToken);
    const response = await API.get(amplifyAPIEndpointName, getPath(path, data), options);
    return response;
  } catch (error) {
    console.error(`Failed to GET ${path}:`, error);
    throw error;
  }
};

/**
 * APIgatewayにPostリクエストを送る
 * @param {string} path
 * @param {object} data
 * @param {string} jwtToken
 * @returns {object} - APIgatewayからのレスポンス
 */
export const Post = async (path, data, jwtToken) => {
  try {
    const options = {
      body: data,
      headers: {
        Authorization: jwtToken,
      },
    };
    const response = await API.post(amplifyAPIEndpointName, path, options);
    return response;
  } catch (error) {
    console.error(`Failed to GET ${path}:`, error);
    throw error;
  }
};
