export const apis = {
  mData: { endpoint: '/sites/measurementData/data' },
  cData: { endpoint: '/forDev/data' },
  siteList: { endpoint: '/sites/data' },
  titleList: { endpoint: '/sites/titles/data' },
  mRainData: { endpoint: '/sites/measurementData/rain/data' },
  graphDataOf24Hour: { endpoint: '/sites/graph/24hour/data' },
  graphDataOf1Hour: { endpoint: '/sites/graph/1hour/data' },
  OneHourGraphDataOfHourRateLevel: { endpoint: '/sites/graph/1hour/level/data' },
  graphDataOf10Minutes: { endpoint: '/sites/graph/10minutes/data' },
  graphDataOf1Minute: { endpoint: '/sites/graph/1minute/data' },
  graphDataOfSelectedTerm: { endpoint: '/sites/graph/selected/data' },
  rainOneDayGraphData: { endpoint: '/sites/rain/graph/oneDay/data' },
  rain1HourGraphData: { endpoint: '/sites/rain/graph/1hour/data' },
  rain10MinutesGraphData: { endpoint: '/sites/rain/graph/10minutes/data' },
  getRainTotalValuesPerPeriod: { endpoint: '/sites/rain/totalValuesOfPeriod/data' },
  getDownloadList: { endpoint: '/sites/download/list' },
  requestZip: { endpoint: '/sites/request' },
  alertSetting: { endpoint: '/sites/setting/alert' },
  setting: { endpoint: '/sites/setting' },
  deleteZip: { endpoint: '/sites/delete/zip' },
  alertHistory: { endpoint: '/sites/alertHistory/data' },
  updateReadStatus: { endpoint: '/sites/alertHistory/update/data' },
  sendTestMail: { endpoint: '/sites/mail/test' },
  selectiveDisplaySetting: { endpoint: '/sites/setting/selectiveDisplay' },
  publicPageSetting: { endpoint: '/publicPage/setting' },
};

// 未認証ユーザーでもアクセスできるようにIAM認証にしているAPIgatewayのpath
export const IAMAuthenticationAPIEndPoints = [
  apis.publicPageSetting.endpoint,
  apis.titleList.endpoint,
  apis.selectiveDisplaySetting.endpoint,
  apis.graphDataOf10Minutes.endpoint,
  apis.mData.endpoint,
  apis.mRainData.endpoint,
  apis.setting.endpoint,
  apis.getRainTotalValuesPerPeriod.endpoint,
  apis.rain10MinutesGraphData.endpoint,
  apis.OneHourGraphDataOfHourRateLevel.endpoint,
  apis.graphDataOf1Hour.endpoint,
  apis.rain1HourGraphData.endpoint,
  apis.graphDataOf24Hour.endpoint,
  apis.rainOneDayGraphData.endpoint,
  apis.alertSetting.endpoint,
];
