import React, { Fragment } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import { CSSTransition } from 'react-transition-group';
import styles from './Alert.module.css';

/**
 * Alertを描画するコンポーネント
 * @param {object} props
 * @param {string} props.message - 表示するメッセージ
 * @param {boolean} props.show - 表示判定のフラグ
 * @param {function} props.onClose
 * @returns {JSX.Element}
 */
const Alert = (props) => {
  const animationTiming = {
    enter: 150,
    exit: 100,
  };

  const defaultStyle = {
    display: 'block',
  };

  const cancel_button = (
    <span className={styles.alert_close_btn} onClick={(event) => props.onClose(event)}>
      閉じる
    </span>
  );

  const icon = <i style={{ fontSize: '2em', color: 'red', marginBottom: '10px' }} className="fa fa-times-circle"></i>;

  // 下記警告回避のためrefを使う
  // findDOMNode is deprecated and will be removed in the next major release.
  // Instead, add a ref directly to the element you want to reference.
  const nodeRef = React.useRef(null);

  return (
    <Fragment>
      <Backdrop show={props.show} zIndex="400" />
      <CSSTransition
        nodeRef={nodeRef}
        mountOnEnter
        unmountOnExit
        in={props.show}
        timeout={animationTiming}
        classNames={{
          enter: '',
          enterActive: styles.ModalOpen,
          exit: '',
          exitActive: styles.ModalOpen,
        }}
      >
        {() => (
          <div style={defaultStyle} ref={nodeRef}>
            <div className={styles.alert_style}>
              <div>
                {icon}
                <p>{props.message}</p>
                <br />
              </div>
              {cancel_button}
            </div>
          </div>
        )}
      </CSSTransition>
    </Fragment>
  );
};

export default Alert;
