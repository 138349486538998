import React, { Component } from "react";
import "./PrintPage.module.css";
import "../../../components/Chart/Print/LineChartComponent/LineChartComponent";
import "../../../components/Chart/Print/BarChartComponent/BarChartComponent";
import "../../../components/Chart/Print/ScatterChartWrapper/ScatterChartWrapper";
import "../../../components/ValuesArea/ValuesArea";
import "../../../components/ValuesAreaOfRain/ValuesAreaOfRain";

class PrintPage extends Component {
  render() {
    return <div id="container"></div>;
  }
}

export default PrintPage;
