export const API_URL = 'https://fuljpqlrne.execute-api.ap-northeast-1.amazonaws.com/prod';

export const EDMS_DOMAIN = 'edms.j-marts.com';

export const cognito = {
  REGION: 'ap-northeast-1',
  USER_POOL_ID: 'ap-northeast-1_tiCnD09HB',
  APP_CLIENT_ID: '7sbl7dk7sk1c1bnt4aqra3qs6o',
  IDENTITY_POOL_ID: 'ap-northeast-1:28d8d70b-7d06-444a-a419-4097d37db5db',
};

export const appSync = {
  url: 'https://lf62cdx2mbenzb4ujuvm5xij4e.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  region: 'ap-northeast-1',
};

export const zipStrage = {
  AWSS3: {
    bucket: 'edms-zip-prod',
    region: 'ap-northeast-1',
  },
};
