import * as types from './actionTypes';

export function checkLogin() {
  return {
    type: types.auth.CHECK_LOGIN_REQUESTED,
  };
}

export function checkLoginFulfilled(user, isAdmin) {
  return {
    type: types.auth.CHECK_LOGIN_FULFILLED,
    payload: { user, isAdmin },
  };
}

export function checkLoginRejected(error) {
  return {
    type: types.auth.CHECK_LOGIN_REJECTED,
    payload: { error },
  };
}

export function getGuestCredentials() {
  return {
    type: types.auth.GUEST_CREDENTIAL_REQUESTED,
  };
}

export function getGuestCredentialsFulfilled(credentials) {
  return {
    type: types.auth.GUEST_CREDENTIAL_FULFILLED,
    payload: { credentials },
  };
}

export function getGuestCredentialsRejected(error) {
  return {
    type: types.auth.GUEST_CREDENTIAL_REJECTED,
    payload: { error },
  };
}

export function login(siteId, password) {
  return {
    type: types.auth.LOGIN_REQUESTED,
    payload: { siteId, password },
  };
}

export function loginFulfilled(user, isAdmin) {
  return {
    type: types.auth.LOGIN_FULFILLED,
    payload: { user, isAdmin },
  };
}

export function loginRejected(error) {
  return {
    type: types.auth.LOGIN_REJECTED,
    payload: { error },
  };
}

export function logout() {
  return {
    type: types.auth.LOGOUT_REQUESTED,
  };
}

export function logoutFulfilled() {
  return {
    type: types.auth.LOGOUT_FULFILLED,
  };
}

export function logoutRejected(error) {
  return {
    type: types.auth.LOGOUT_REJECTED,
    payload: { error },
  };
}
