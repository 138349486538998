import { Auth } from 'aws-amplify';
import * as types from '../actions/actionTypes';
import * as actions from '../actions';
import { put, take, call, select, fork } from 'redux-saga/effects';
import { getAuth } from '../selectors/selectors';

function authException(message) {
  this.message = message;
  this.name = 'authException';
}

function _checkLogin() {
  try {
    return Auth.currentAuthenticatedUser();
  } catch (error) {
    throw new authException(error);
  }
}

function isAdmin(user) {
  if (!user.attributes) return false;
  if (!user.attributes['custom:isAdmin']) return false;
  return user.attributes['custom:isAdmin'] === '1';
}

export function* checkLogin() {
  try {
    let user = yield call(_checkLogin);
    yield put(actions.auth.checkLoginFulfilled(user, isAdmin(user)));
  } catch (error) {
    yield put(actions.auth.checkLoginRejected(error));
  }
}

export function login(siteId, password) {
  try {
    return Auth.signIn(siteId, password).then(() => {
      return Auth.currentAuthenticatedUser();
    });
  } catch (error) {
    throw new authException(error);
  }
}

export function getGuestCredentials() {
  try {
    return Auth.currentCredentials();
  } catch (error) {
    throw new authException(error);
  }
}

export function logout() {
  try {
    return Auth.signOut();
  } catch (error) {
    throw new authException(error);
  }
}

export function* watchAuthListener() {
  while (true) {
    const action = yield take([
      types.auth.CHECK_LOGIN_REQUESTED,
      types.auth.GUEST_CREDENTIAL_REQUESTED,
      types.auth.LOGIN_REQUESTED,
      types.auth.LOGOUT_REQUESTED,
    ]);
    try {
      const auth = yield select(getAuth);
      if (
        (!auth.is_authenticated && action.type === types.auth.CHECK_LOGIN_REQUESTED) ||
        (!auth.is_authenticated && action.type === types.auth.GUEST_CREDENTIAL_REQUESTED) ||
        (!auth.is_authenticated && action.type === types.auth.LOGIN_REQUESTED) ||
        (auth.is_authenticated && action.type === types.auth.LOGOUT_REQUESTED)
      ) {
        switch (action.type) {
          case types.auth.CHECK_LOGIN_REQUESTED:
            yield fork(checkLogin);
            break;
          case types.auth.GUEST_CREDENTIAL_REQUESTED:
            const credentials = yield call(getGuestCredentials);
            yield put(actions.auth.getGuestCredentialsFulfilled(credentials));
            break;
          case types.auth.LOGIN_REQUESTED:
            const userLogin = yield call(login, action.payload.siteId, action.payload.password);
            yield put(actions.auth.loginFulfilled(userLogin, isAdmin(userLogin)));
            break;
          case types.auth.LOGOUT_REQUESTED:
            yield call(logout);
            yield put(actions.auth.logoutFulfilled());
            break;
          default:
            break;
        }
      } else {
        throw new TypeError('Not Authenticated');
      }
    } catch (error) {
      switch (action.type) {
        case types.auth.CHECK_LOGIN_REQUESTED:
          yield put(actions.auth.checkLoginRejected(error));
          break;
        case types.auth.GUEST_CREDENTIAL_REQUESTED:
          yield put(actions.auth.getGuestCredentialsRejected(error));
          break;
        case types.auth.LOGIN_REQUESTED:
          yield put(actions.auth.loginRejected(error));
          break;
        case types.auth.LOGOUT_REQUESTED:
          yield put(actions.auth.logoutRejected(error));
          break;
        default:
          break;
      }
      if (error.message) {
        if (error.message === 'Incorrect username or password.' || error.message === 'User does not exist.') {
          error.message = 'IDまたはパスワードが間違っています';
        }
      }
      yield put(
        actions.app.showAlert({
          alert_type: 'fail',
          alert_message: error.message ? error.message : error,
        }),
      );
    }
  }
}
