import { metaAppTypes } from '../actions/actionTypes';
import * as appSagas from './appSagas';
import * as authSagas from './authSagas';

import { all } from 'redux-saga/effects';

const rootSaga = function* rootSaga() {
  yield all([
    appSagas.processAppActions(metaAppTypes.getMeasurementData),
    appSagas.processAppActions(metaAppTypes.getCheckingData),
    appSagas.processAppActions(metaAppTypes.getSiteList),
    appSagas.processAppActions(metaAppTypes.getTitleList),
    appSagas.processAppActions(metaAppTypes.getMRainData),
    appSagas.processAppActions(metaAppTypes.getGraphDataOf24Hour),
    appSagas.processAppActions(metaAppTypes.getGraphDataOf1Hour),
    appSagas.processAppActions(metaAppTypes.getGraphDataOf10Minutes),
    appSagas.processAppActions(metaAppTypes.getGraphDataOf1Minute),
    appSagas.processAppActions(metaAppTypes.getGraphDataOfSelectedTerm),
    appSagas.processAppActions(metaAppTypes.get1HourGraphDataOfHourRateLevel),
    appSagas.processAppActions(metaAppTypes.getRainOneDayGraphData),
    appSagas.processAppActions(metaAppTypes.getRain1HourGraphData),
    appSagas.processAppActions(metaAppTypes.getRain10MinutesGraphData),
    appSagas.processAppActions(metaAppTypes.getRainTotalValuesPerPeriod),
    appSagas.processAppActions(metaAppTypes.getDownloadList),
    appSagas.processAppActions(metaAppTypes.requestZip),
    appSagas.processAppActions(metaAppTypes.updateAlertSetting),
    appSagas.processAppActions(metaAppTypes.getAlertSetting),
    appSagas.processAppActions(metaAppTypes.getSetting),
    appSagas.processAppActions(metaAppTypes.deleteZip),
    appSagas.processAppActions(metaAppTypes.getAlertHistory),
    appSagas.processAppActions(metaAppTypes.updateReadStatus),
    appSagas.processAppActions(metaAppTypes.sendTestMail),
    appSagas.processAppActions(metaAppTypes.updateSelectiveDisplaySetting),
    appSagas.processAppActions(metaAppTypes.getSelectiveDisplaySetting),
    appSagas.processAppActions(metaAppTypes.getPublicPageSetting),

    appSagas.processAppSync(metaAppTypes.getLatestAlertHistory),
    appSagas.processAppSync(metaAppTypes.getUnreadAlertHistory),
    authSagas.watchAuthListener(),
  ]);
};

export default rootSaga;
