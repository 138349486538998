import React, { Component } from "react";
import ScatterChartComponent from "./ScatterChartComponent";
import dayjs from "../../../../lib/dayjsSetup";

class ScatterChartWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.params.length === nextProps.params.length) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const params = this.props.params[0];
    const diff_hour = params.diff_hour;
    // props.dataをそのまま修正するとstateが書き換わってしまうため別の配列にコピーする
    let graph_data = [];
    for (let i = 0; i < params.graph_data.length; i++) {
      graph_data.push({ ...params.graph_data[i] });
    }
    const start_date = dayjs(params.start).tz("Asia/Tokyo");
    const end_date = dayjs(params.end).tz("Asia/Tokyo");
    let i = 0;
    let base_date = start_date.add(1, "millisecond");
    const graph_array = [];
    while (base_date.isBefore(end_date, "second")) {
      const obj = {};
      if (graph_data[i]) {
        const date_from_graph_data = dayjs(graph_data[i].date).tz("Asia/Tokyo");
        if (diff_hour > 3) {
          // 10分データ作成時にcronの発火ミスで10分刻み以外のデータが作成されることがある。もし半端なデータが来た場合はここで弾く
          const strMinute = String(date_from_graph_data.minute());
          const targetMinute =
            strMinute.length === 1 ? strMinute : strMinute.slice(-1);
          if (targetMinute !== "0") {
            i++;
            continue;
          }
        }
        if (date_from_graph_data.isSame(base_date, "minute")) {
          obj.date = date_from_graph_data.format("YYYY年MM月DD日 HH:mm");
          obj.value = graph_data[i].value;
          i++;
        } else {
          obj.date = base_date.format("YYYY年MM月DD日 HH:mm");
          obj.value = null;
        }
      } else {
        obj.date = base_date.format("YYYY年MM月DD日 HH:mm");
        obj.value = null;
        i++;
      }
      if (diff_hour > 3) {
        base_date = base_date.add(10, "minute");
      } else {
        base_date = base_date.add(1, "minute");
      }
      graph_array.push(obj);
    }
    params.graph_data = graph_array;

    let date_array = [];
    params.graph_data.forEach((dt) => {
      const obj = {
        date: dt.date,
      };
      date_array.push(obj);
    });
    return (
      <ScatterChartComponent
        params={params}
        date_array={date_array}
        start_index={0}
        end_index={date_array.length - 1}
      />
    );
  }
}

export default ScatterChartWrapper;
