import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

export function getInitialState() {
  let state = {};
  Object.keys(types.metaAppTypes).forEach((key) => {
    const subState = { inProgress: false, error: '' };
    state[key] = subState;
  });
  state = {
    ...state,
    show_alert: false,
    alert_type: 'fail',
    alert_message: '',
    measurement_data: '',
    checking_data: '',
    site_list: '',
    title_list: '',
    mRain_data: '',
    graph_data_of_24hour: '',
    graph_data_of_1hour: '',
    graph_data_of_10minutes: '',
    graph_data_of_1minute: '',
    rain_one_day_graph_data: '',
    rain_1hour_graph_data: '',
    rain_10minutes_graph_data: '',
    rain_total_values_per_period: '',
    request_zip: '',
    get_alert_setting: '',
    get_setting: '',
    delete_zip: '',
    alert_history: '',
    graph_data_of_1hour_rate_level: '',
    get_selective_display_setting: '',
    get_public_page_setting: '',
  };
  return state;
}

const initialState = getInitialState();

export const appReducer = createReducer(initialState, {
  [types.app.SHOW_ALERT](state, action) {
    let newState = {
      ...state,
      show_alert: true,
      alert_type: action.payload.alert_type,
      alert_message: action.payload.alert_message,
    };
    return newState;
  },
  [types.app.HIDE_ALERT](state, action) {
    let newState = {
      ...state,
      show_alert: false,
      alert_type: 'fail',
      alert_message: '',
    };
    return newState;
  },
  [types.data.GET_DATA_REQUESTED](state, action) {
    const property = action.meta.type;
    const key = action.meta.key;
    let newState = {
      ...state,
      [property]: { inProgress: true, error: '' },
      [key]: '',
    };
    return newState;
  },
  [types.data.GET_DATA_FULFILLED](state, action) {
    const property = action.meta.type;
    const key = action.meta.key;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: '' },
      [key]: action.payload,
    };
    return newState;
  },
  [types.data.GET_DATA_REJECTED](state, action) {
    const error = action.payload.error;
    const property = action.meta.type;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: error },
    };
    return newState;
  },
  [types.data.POST_DATA_REQUESTED](state, action) {
    const property = action.meta.type;
    let newState = {
      ...state,
      [property]: { inProgress: true, error: '' },
    };
    return newState;
  },
  [types.data.POST_DATA_FULFILLED](state, action) {
    const property = action.meta.type;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: '' },
    };
    return newState;
  },
  [types.data.POST_DATA_REJECTED](state, action) {
    const error = action.payload.error;
    const property = action.meta.type;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: error },
    };
    return newState;
  },
  [types.data.GRAPHQL_REQUESTED](state, action) {
    const property = action.meta.type;
    const key = action.meta.key;
    let newState = {
      ...state,
      [property]: { inProgress: true, error: null },
      [key]: '',
    };
    return newState;
  },
  [types.data.GRAPHQL_FULFILLED](state, action) {
    const property = action.meta.type;
    const key = action.meta.key;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: '' },
      [key]: action.payload.result,
    };
    return newState;
  },
  [types.data.GRAPHQL_REJECTED](state, action) {
    const error = action.payload.error;
    const property = action.meta.type;
    let newState = {
      ...state,
      [property]: { inProgress: false, error: error },
    };
    return newState;
  },
});
