import React from "react";
import { Navigate } from "react-router-dom";

const renderComponent = (C, cProps) => {
  const directories = window.location.pathname.split("/");

  if (!cProps.is_authenticated) {
    const pathname = cProps.location.pathname;

    if (!pathname) {
      return <Navigate to="/admin" />;
    }
    const search = cProps.location.search;

    if (["/"].indexOf(pathname) > -1 || directories.length < 2) {
      return <Navigate to={`/admin?redirect=${pathname}${search}`} />;
    }

    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  if (!directories[0]) {
    directories[0] = "measurement-screen";
  }

  const isAdmin = cProps.is_admin;
  const userName = cProps.user.username;
  if (!isAdmin && directories[1] === "check-data") {
    return <Navigate to={`/${directories[0]}/${userName}`} />;
  }

  if (!isAdmin && (directories.length < 2 || directories[2] !== userName)) {
    return <Navigate to={`/${directories[0]}/${userName}`} />;
  }

  return <C {...cProps} />;
};

export default ({ component: C, props: cProps }) => {
  return renderComponent(C, cProps);
};
