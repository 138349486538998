import * as types from './actionTypes';
import { apis } from './apiEndPoints';

export function getMeasurementDataRequested(id) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: id,
    meta: { type: types.metaAppTypes.getMeasurementData, api: apis.mData, key: 'measurement_data' },
  };
}

export function getMeasurementDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getMeasurementData, key: 'measurement_data' },
  };
}

export function getMeasurementDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getMeasurementData },
  };
}

export function getCheckingDataRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.getCheckingData, api: apis.cData, key: 'checking_data' },
  };
}

export function getCheckingDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getCheckingData, key: 'checking_data' },
  };
}

export function getCheckingDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getCheckingData },
  };
}

export function getSiteListRequested() {
  return {
    type: types.data.GET_DATA_REQUESTED,
    meta: { type: types.metaAppTypes.getSiteList, api: apis.siteList, key: 'site_list' },
  };
}

export function getSiteListFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getSiteList, key: 'site_list' },
  };
}

export function getSiteListRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getSiteList },
  };
}

export function getTitleListRequested(siteId) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: siteId,
    meta: { type: types.metaAppTypes.getTitleList, api: apis.titleList, key: 'title_list' },
  };
}

export function getTitleListFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getTitleList, key: 'title_list' },
  };
}

export function getTitleListRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getTitleList },
  };
}

export function getMRainDataRequested(siteId) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: siteId,
    meta: { type: types.metaAppTypes.getMRainData, api: apis.mRainData, key: 'mRain_data' },
  };
}

export function getMRainDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getMRainData, key: 'mRain_data' },
  };
}

export function getMRainDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getMRainData },
  };
}

export function getGraphDataOf24HourRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.getGraphDataOf24Hour, api: apis.graphDataOf24Hour, key: 'graph_data_of_24hour' },
  };
}

export function getGraphDataOf24HourFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getGraphDataOf24Hour, key: 'graph_data_of_24hour' },
  };
}

export function getGraphDataOf24HourRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getGraphDataOf24Hour },
  };
}

export function getGraphDataOf1HourRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.getGraphDataOf1Hour, api: apis.graphDataOf1Hour, key: 'graph_data_of_1hour' },
  };
}

export function getGraphDataOf1HourFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getGraphDataOf1Hour, key: 'graph_data_of_1hour' },
  };
}

export function getGraphDataOf1HourRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getGraphDataOf1Hour },
  };
}

export function getGraphDataOf10MinutesRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getGraphDataOf10Minutes,
      api: apis.graphDataOf10Minutes,
      key: 'graph_data_of_10minutes',
    },
  };
}

export function getGraphDataOf10MinutesFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getGraphDataOf10Minutes, key: 'graph_data_of_10minutes' },
  };
}

export function getGraphDataOf10MinutesRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getGraphDataOf10Minutes },
  };
}

export function getGraphDataOf1MinuteRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getGraphDataOf1Minute,
      api: apis.graphDataOf1Minute,
      key: 'graph_data_of_1minute',
    },
  };
}

export function getGraphDataOf1MinuteFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getGraphDataOf1Minute, key: 'graph_data_of_1minute' },
  };
}

export function getGraphDataOf1MinuteRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getGraphDataOf1Minute },
  };
}

export function getGraphDataOfSelectedTermRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getGraphDataOfSelectedTerm,
      api: apis.graphDataOfSelectedTerm,
      key: 'graph_data_of_selected_term',
    },
  };
}

export function getGraphDataOfSelectedTermFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getGraphDataOfSelectedTerm, key: 'graph_data_of_selected_term' },
  };
}

export function getGraphDataOfSelectedTermRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getGraphDataOfSelectedTerm },
  };
}

export function get1HourGraphDataOfHourRateLevelRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.get1HourGraphDataOfHourRateLevel,
      api: apis.OneHourGraphDataOfHourRateLevel,
      key: 'graph_data_of_1hour_rate_level',
    },
  };
}

export function get1HourGraphDataOfHourRateLevelFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.get1HourGraphDataOfHourRateLevel, key: 'graph_data_of_1hour_rate_level' },
  };
}

export function get1HourGraphDataOfHourRateLevelRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.get1HourGraphDataOfHourRateLevel },
  };
}

export function getRainOneDayGraphDataRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getRainOneDayGraphData,
      api: apis.rainOneDayGraphData,
      key: 'rain_one_day_graph_data',
    },
  };
}

export function getRainOneDayGraphDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getRainOneDayGraphData, key: 'rain_one_day_graph_data' },
  };
}

export function getRainOneDayGraphDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getRainOneDayGraphData },
  };
}

export function getRain1HourGraphDataRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getRain1HourGraphData,
      api: apis.rain1HourGraphData,
      key: 'rain_1hour_graph_data',
    },
  };
}

export function getRain1HourGraphDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getRain1HourGraphData, key: 'rain_1hour_graph_data' },
  };
}

export function getRain1HourGraphDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getRain1HourGraphData },
  };
}

export function getRain10MinutesGraphDataRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getRain10MinutesGraphData,
      api: apis.rain10MinutesGraphData,
      key: 'rain_10minutes_graph_data',
    },
  };
}

export function getRain10MinutesGraphDataFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getRain10MinutesGraphData, key: 'rain_10minutes_graph_data' },
  };
}

export function getRain10MinutesGraphDataRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getRain10MinutesGraphData },
  };
}

export function getRainTotalValuesPerPeriodRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: {
      type: types.metaAppTypes.getRainTotalValuesPerPeriod,
      api: apis.getRainTotalValuesPerPeriod,
      key: 'rain_total_values_per_period',
    },
  };
}

export function getRainTotalValuesPerPeriodFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getRainTotalValuesPerPeriod, key: 'rain_total_values_per_period' },
  };
}

export function getRainTotalValuesPerPeriodRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getRainTotalValuesPerPeriod },
  };
}

export function getDownloadListRequested(siteId) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: siteId,
    meta: { type: types.metaAppTypes.getDownloadList, api: apis.getDownloadList, key: 'download_list' },
  };
}

export function getDownloadListFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getDownloadList, key: 'download_list' },
  };
}

export function getDownloadListRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getDownloadList },
  };
}

export function requestZipRequested(data) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.requestZip, api: apis.requestZip, key: 'request_zip' },
  };
}

export function requestZipFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.requestZip, key: 'request_zip' },
  };
}

export function requestZipRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.requestZip },
  };
}

export function getAlertSettingRequested(data) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.getAlertSetting, api: apis.alertSetting, key: 'get_alert_setting' },
  };
}

export function getAlertSettingFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getAlertSetting, key: 'get_alert_setting' },
  };
}

export function getAlertSettingRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getAlertSetting },
  };
}

export function getSettingRequested(data) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.getSetting, api: apis.setting, key: 'get_setting' },
  };
}

export function getSettingFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getSetting, key: 'get_setting' },
  };
}

export function getSettingRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getSetting },
  };
}

export function getAlertHistoryRequested(params) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.getAlertHistory, api: apis.alertHistory, key: 'alert_history' },
  };
}

export function getAlertHistoryFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getAlertHistory, key: 'alert_history' },
  };
}

export function getAlertHistoryRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getAlertHistory },
  };
}

export function getSelectiveDisplaySettingRequested(data) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: data,
    meta: {
      type: types.metaAppTypes.getSelectiveDisplaySetting,
      api: apis.selectiveDisplaySetting,
      key: 'get_selective_display_setting',
    },
  };
}

export function getSelectiveDisplaySettingFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getSelectiveDisplaySetting, key: 'get_selective_display_setting' },
  };
}

export function getSelectiveDisplaySettingRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getSelectiveDisplaySetting },
  };
}

export function getPublicPageSettingRequested(data) {
  return {
    type: types.data.GET_DATA_REQUESTED,
    payload: data,
    meta: {
      type: types.metaAppTypes.getPublicPageSetting,
      api: apis.publicPageSetting,
      key: 'get_public_page_setting',
    },
  };
}

export function getPublicPageSettingFulfilled(data) {
  return {
    type: types.data.GET_DATA_FULFILLED,
    payload: data,
    meta: { type: types.metaAppTypes.getPublicPageSetting, key: 'get_public_page_setting' },
  };
}

export function getPublicPageSettingRejected(error) {
  return {
    type: types.data.GET_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getPublicPageSetting },
  };
}

// POST関連>>>>>>>>>>>>>>>>>>>>>>>
export function sendTestMailRequested(data) {
  return {
    type: types.data.POST_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.sendTestMail, api: apis.sendTestMail },
  };
}

export function sendTestMailFulfilled(result) {
  return {
    type: types.data.POST_DATA_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.sendTestMail },
  };
}

export function sendTestMailRejected(error) {
  return {
    type: types.data.POST_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.sendTestMail },
  };
}

export function updateReadStatusRequested(params) {
  return {
    type: types.data.POST_DATA_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.updateReadStatus, api: apis.updateReadStatus },
  };
}

export function updateReadStatusFulfilled(result) {
  return {
    type: types.data.POST_DATA_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.updateReadStatus },
  };
}

export function updateReadStatusRejected(error) {
  return {
    type: types.data.POST_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.updateReadStatus },
  };
}

export function deleteZipRequested(data) {
  return {
    type: types.data.POST_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.deleteZip, api: apis.deleteZip },
  };
}

export function deleteZipFulfilled(result) {
  return {
    type: types.data.POST_DATA_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.deleteZip },
  };
}

export function deleteZipRejected(error) {
  return {
    type: types.data.POST_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.deleteZip },
  };
}

export function updateAlertSettingRequested(data) {
  return {
    type: types.data.POST_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.updateAlertSetting, api: apis.alertSetting },
  };
}

export function updateAlertSettingFulfilled(result) {
  return {
    type: types.data.POST_DATA_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.updateAlertSetting },
  };
}

export function updateAlertSettingRejected(error) {
  return {
    type: types.data.POST_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.updateAlertSetting },
  };
}

export function updateSelectiveDisplaySettingRequested(data) {
  return {
    type: types.data.POST_DATA_REQUESTED,
    payload: data,
    meta: { type: types.metaAppTypes.updateSelectiveDisplaySetting, api: apis.selectiveDisplaySetting },
  };
}

export function updateSelectiveDisplaySettingFulfilled(result) {
  return {
    type: types.data.POST_DATA_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.updateSelectiveDisplaySetting },
  };
}

export function updateSelectiveDisplaySettingRejected(error) {
  return {
    type: types.data.POST_DATA_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.updateSelectiveDisplaySetting },
  };
}

// GRAPHQL関連>>>>>>>>>>>>>>>>>>>>>>>
export function getLatestAlertHistoryRequested(params) {
  return {
    type: types.data.GRAPHQL_REQUESTED,
    payload: params,
    meta: { type: types.metaAppTypes.getLatestAlertHistory, key: 'latest_alert_history' },
  };
}

export function getLatestAlertHistoryFulfilled(result) {
  return {
    type: types.data.GRAPHQL_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.getLatestAlertHistory, key: 'latest_alert_history' },
  };
}

export function getLatestAlertHistoryRejected(error) {
  return {
    type: types.data.GRAPHQL_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getLatestAlertHistory },
  };
}

export function getUnreadAlertHistoryRequested(param) {
  return {
    type: types.data.GRAPHQL_REQUESTED,
    payload: param,
    meta: { type: types.metaAppTypes.getUnreadAlertHistory, key: 'unread_alert_history' },
  };
}

export function getUnreadAlertHistoryFulfilled(result) {
  return {
    type: types.data.GRAPHQL_FULFILLED,
    payload: { result },
    meta: { type: types.metaAppTypes.getUnreadAlertHistory, key: 'unread_alert_history' },
  };
}

export function getUnreadAlertHistoryRejected(error) {
  return {
    type: types.data.GRAPHQL_REJECTED,
    payload: { error },
    meta: { type: types.metaAppTypes.getUnreadAlertHistory },
  };
}
