export const auth = {
  CHECK_LOGIN_REQUESTED: 'CHECK_LOGIN_REQUESTED',
  CHECK_LOGIN_FULFILLED: 'CHECK_LOGIN_FULFILLED',
  CHECK_LOGIN_REJECTED: 'CHECK_LOGIN_REJECTED',

  GUEST_CREDENTIAL_REQUESTED: 'GUEST_CREDENTIAL_REQUESTED',
  GUEST_CREDENTIAL_FULFILLED: 'GUEST_CREDENTIAL_FULFILLED',
  GUEST_CREDENTIAL_REJECTED: 'GUEST_CREDENTIAL_REJECTED',

  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_FULFILLED: 'LOGIN_FULFILLED',
  LOGIN_REJECTED: 'LOGIN_REJECTED',

  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  LOGOUT_FULFILLED: 'LOGOUT_FULFILLED',
  LOGOUT_REJECTED: 'LOGOUT_REJECTED',
};

export const data = {
  GET_DATA_REQUESTED: 'GET_DATA_REQUESTED',
  GET_DATA_FULFILLED: 'GET_DATA_FULFILLED',
  GET_DATA_REJECTED: 'GET_DATA_REJECTED',

  POST_DATA_REQUESTED: 'POST_DATA_REQUESTED',
  POST_DATA_FULFILLED: 'POST_DATA_FULFILLED',
  POST_DATA_REJECTED: 'POST_DATA_REJECTED',

  GRAPHQL_REQUESTED: 'GRAPHQL_REQUESTED',
  GRAPHQL_FULFILLED: 'GRAPHQL_FULFILLED',
  GRAPHQL_REJECTED: 'GRAPHQL_REJECTED',
};

export const app = {
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
};

export const metaAppTypes = {
  getMeasurementData: 'getMeasurementData',
  getCheckingData: 'getCheckingData',
  getSiteList: 'getSiteList',
  getTitleList: 'getTitleList',
  getMRainData: 'getMRainData',
  getGraphDataOf24Hour: 'getGraphDataOf24Hour',
  getGraphDataOf1Hour: 'getGraphDataOf1Hour',
  getGraphDataOf10Minutes: 'getGraphDataOf10Minutes',
  getGraphDataOf1Minute: 'getGraphDataOf1Minute',
  getGraphDataOfSelectedTerm: 'getGraphDataOfSelectedTerm',
  get1HourGraphDataOfHourRateLevel: 'get1HourGraphDataOfHourRateLevel',
  getRainOneDayGraphData: 'getRainOneDayGraphData',
  getRain1HourGraphData: 'getRain1HourGraphData',
  getRain10MinutesGraphData: 'getRain10MinutesGraphData',
  getRainTotalValuesPerPeriod: 'getRainTotalValuesPerPeriod',
  getDownloadList: 'getDownloadList',
  requestZip: 'requestZip',
  updateAlertSetting: 'updateAlertSetting',
  getAlertSetting: 'getAlertSetting',
  getSetting: 'getSetting',
  deleteZip: 'deleteZip',
  getAlertHistory: 'getAlertHistory',
  updateReadStatus: 'updateReadStatus',
  getLatestAlertHistory: 'getLatestAlertHistory',
  getUnreadAlertHistory: 'getUnreadAlertHistory',
  sendTestMail: 'sendTestMail',
  updateSelectiveDisplaySetting: 'updateSelectiveDisplaySetting',
  getSelectiveDisplaySetting: 'getSelectiveDisplaySetting',
  getPublicPageSetting: 'getPublicPageSetting',
};
