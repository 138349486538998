import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { appSync } from '../config/index';

const appSyncClient = new AWSAppSyncClient({
  url: appSync.url,
  region: appSync.region,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => {
      return await Auth.currentCredentials(); // 認証情報を取得
    },
  },
  disableOffline: true,
});

export default appSyncClient;
