import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

/**
 * Footerコンポーネントを描画する
 * @component
 * @param {object} props
 * @param {function} props.setDefaultState - stateとsubscriptionを初期化する
 * @returns {JSX.Element}
 */
const Footer = ({ setDefaultState }) => {
  return (
    <footer className={styles.footer}>
      <Link to="/" onClick={setDefaultState}>
        <img src="../images/header_logo.png" alt="service logo" />
      </Link>
      <div className={styles.sytem_name}>測定監視システムEDMS</div>
      <div className={styles.space}></div> {/* スペース用 */}
    </footer>
  );
};

export default Footer;
