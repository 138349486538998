import React, { Component } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "@bitriver/recharts-v2";
import styles from "./LineChartComponent.module.css";
import "../../style.css";
import dayjs from "../../../../lib/dayjsSetup";

class LineChartComponent extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.params.length === nextProps.params.length) {
      return false;
    } else {
      return true;
    }
  }

  getXaxisParams = (diff_hour, cycle) => {
    let interval = 9;
    let angle = -35;
    if (diff_hour > 48) {
      interval = 35;
    } else if (diff_hour > 24) {
      interval = 11;
    } else if (diff_hour > 8) {
      interval = 5;
    } else if (diff_hour > 3) {
      interval = 2;
    } else if (diff_hour > 1) {
      if (cycle === 600) {
        interval = 0;
      } else {
        interval = 9;
      }
    } else if (diff_hour > 0.33) {
      // 20分以上
      if (cycle > 60) {
        interval = 0;
      } else {
        interval = 4;
      }
    } else if (diff_hour >= 0.06) {
      // 4分以上
      if (cycle < 60) {
        interval = 59;
        angle = -15;
      } else {
        interval = 0;
      }
    } else if (diff_hour >= 0.03) {
      // 2分以上
      angle = -15;
      interval = 19;
    } else {
      angle = -15;
      interval = 9;
    }
    const xAxis_params = {
      interval,
      angle,
      height: 45,
      dy: 10,
      fontSize: 15,
      tickFormatter: this.getTickFormatter(diff_hour),
    };
    return xAxis_params;
  };

  getTickFormatter = (diff_hour) => {
    let tickFormatter = (props) => {
      return props.split(" ")[1];
    };
    if (diff_hour > 1) {
      tickFormatter = (props) => {
        const time = props.split(" ")[1];
        const splitedTime = time.split(":");
        return `${splitedTime[0]}:${splitedTime[1]}`;
      };
    }
    return tickFormatter;
  };

  getYaxisDomain = (
    min_value,
    max_value,
    min_of_graph_range,
    max_of_graph_range,
    value_to_change_graph_range
  ) => {
    let domain = [0, Math.ceil(max_value) + 10];
    if (
      min_of_graph_range !== undefined &&
      max_of_graph_range !== undefined &&
      value_to_change_graph_range !== undefined
    ) {
      if (min_value >= min_of_graph_range && max_value <= max_of_graph_range) {
        domain = [min_of_graph_range, max_of_graph_range];
      } else {
        const data_abs = Math.abs(
          Math.round((max_value - min_value) * 100) / 100
        );
        const graph_range_abs = Math.abs(
          Math.round((max_of_graph_range - min_of_graph_range) * 100) / 100
        );
        if (data_abs <= graph_range_abs) {
          let shift_width;
          if (max_value >= max_of_graph_range) {
            shift_width =
              Math.ceil(
                Math.round((max_value - max_of_graph_range) * 100) /
                  100 /
                  value_to_change_graph_range
              ) * value_to_change_graph_range;
          } else {
            shift_width =
              Math.floor(
                Math.round((min_value - min_of_graph_range) * 100) /
                  100 /
                  value_to_change_graph_range
              ) * value_to_change_graph_range;
          }
          max_of_graph_range = max_of_graph_range + shift_width;
          min_of_graph_range = min_of_graph_range + shift_width;
          domain = [min_of_graph_range, max_of_graph_range];
        } else {
          if (max_value > max_of_graph_range) {
            const shift_width_of_max_range =
              Math.ceil(
                Math.round((max_value - max_of_graph_range) * 100) /
                  100 /
                  value_to_change_graph_range
              ) * value_to_change_graph_range;
            max_of_graph_range = max_of_graph_range + shift_width_of_max_range;
          }
          if (min_value < min_of_graph_range) {
            const shift_width_of_min_range =
              Math.floor(
                Math.round((min_value - min_of_graph_range) * 100) /
                  100 /
                  value_to_change_graph_range
              ) * value_to_change_graph_range;
            min_of_graph_range = min_of_graph_range + shift_width_of_min_range;
          }
          domain = [min_of_graph_range, max_of_graph_range];
        }
      }
    }
    return domain;
  };

  getYaxisTickSpan = (yAxis_domain) => {
    const THRESHOLD_NUMBER_Of_TICKS = 7;
    const mantissa_table = [1, 2, 5, 10, 20, 50];
    const min_of_graph_range = yAxis_domain[0];
    const max_of_graph_range = yAxis_domain[1];
    const span =
      Math.round((max_of_graph_range - min_of_graph_range) * 100) / 100;
    const index = Math.floor(Math.log10(span)) - 1;
    let tick_span;
    for (let i = 0; i < mantissa_table.length; i++) {
      tick_span = mantissa_table[i] * Math.pow(10, index);
      const number_of_ticks = Math.floor(span / tick_span);
      if (number_of_ticks < THRESHOLD_NUMBER_Of_TICKS) {
        break;
      }
    }
    return tick_span;
  };

  getYaxisTicks = (yAxis_domain, yAxis_tick_span) => {
    const min_of_graph_range = yAxis_domain[0];
    const max_of_graph_range = yAxis_domain[1];
    let ticks = [];
    let tick_value = min_of_graph_range;
    while (tick_value < max_of_graph_range) {
      tick_value = Math.round((tick_value + yAxis_tick_span) * 10) / 10;
      if (tick_value < max_of_graph_range) {
        ticks.push(tick_value);
      }
    }
    const yAxis_ticks = [min_of_graph_range, ...ticks, max_of_graph_range];
    return yAxis_ticks;
  };

  getEvery1MinutesData = (param, graph_data) => {
    let startTime = dayjs(param.start).add(1, "millisecond");
    let endTime = startTime.add(1, "minute");
    const loopEndTime = dayjs(param.end);
    const every1MinutesData = [];
    while (startTime.isBefore(loopEndTime, "second")) {
      const currentStartTime = startTime;
      const currentEndTime = endTime;
      const muchData = [];
      graph_data = graph_data.filter((data) => {
        if (!(data.date >= currentStartTime && data.date < currentEndTime)) {
          return true;
        }
        muchData.push(data);
        return false;
      });
      if (muchData.length) {
        every1MinutesData.push({
          date: startTime.valueOf(),
          value: muchData[muchData.length - 1].value,
          unit: muchData[muchData.length - 1].unit,
          digits: muchData[muchData.length - 1].digits,
        });
      }
      startTime = startTime.add(1, "minute");
      endTime = endTime.add(1, "minute");
    }
    return every1MinutesData;
  };

  getNullPaddingedGraphData = (param, diff_hour, graph_data) => {
    let null_paddinged_graph_data = [];
    const cycle = param.cycle;
    const is_short_term_and_short_cycle =
      diff_hour <= 0.33 && cycle === 1 ? true : false;
    const target = is_short_term_and_short_cycle ? "second" : "minute";

    const start_date = dayjs(param.start).tz("Asia/Tokyo");
    const end_date = dayjs(param.end).tz("Asia/Tokyo");
    let i = 0;
    let base_date = start_date.add(1, "millisecond");
    while (base_date.isBefore(end_date, "second")) {
      const obj = {};
      if (graph_data[i]) {
        const date_from_graph_data = dayjs(graph_data[i].date).tz("Asia/Tokyo");
        if (diff_hour > 3 || cycle === 600) {
          // 10分データ作成時にcronの発火ミスで10分刻み以外のデータが作成されることがある。もし半端なデータが来た場合はここで弾く
          const strMinutes = String(date_from_graph_data.minute());
          const targetMinutes =
            strMinutes.length === 1 ? strMinutes : strMinutes.slice(-1);
          if (targetMinutes !== "0") {
            i++;
            continue;
          }
        }

        if (date_from_graph_data.isSame(base_date, target)) {
          obj.date = is_short_term_and_short_cycle
            ? date_from_graph_data.format("YYYY年MM月DD日(ddd) HH:mm:ss")
            : date_from_graph_data.format("YYYY年MM月DD日(ddd) HH:mm");
          obj.value = graph_data[i].value;
          i++;
        } else {
          obj.date = is_short_term_and_short_cycle
            ? base_date.format("YYYY年MM月DD日(ddd) HH:mm:ss")
            : base_date.format("YYYY年MM月DD日(ddd) HH:mm");
          obj.value = null;
        }
      } else {
        obj.date = is_short_term_and_short_cycle
          ? base_date.format("YYYY年MM月DD日(ddd) HH:mm:ss")
          : base_date.format("YYYY年MM月DD日(ddd) HH:mm");
        obj.value = null;
        i++;
      }
      if (diff_hour > 3 || cycle === 600) {
        base_date = base_date.add(10, "minute");
      } else {
        if (is_short_term_and_short_cycle) {
          base_date = base_date.add(1, "second");
        } else {
          base_date = base_date.add(1, "minute");
        }
      }
      null_paddinged_graph_data.push(obj);
    }
    return null_paddinged_graph_data;
  };

  render() {
    const params = this.props.params[0];
    const diff_hour = params.diff_hour;
    const cycle = params.cycle;
    const max_value = params.max_value;
    const min_value = params.min_value;
    const max_of_graph_range = params.max_of_graph_range;
    const min_of_graph_range = params.min_of_graph_range;
    const value_to_change_graph_range = params.value_to_change_graph_range;

    // props.dataをそのまま修正するとstateが書き換わってしまうため別の配列にコピーする
    let graph_data = [];
    for (let i = 0; i < params.graph_data.length; i++) {
      graph_data.push({ ...params.graph_data[i] });
    }

    // 単位を特定する
    let unit;
    for (let i = 0; i < graph_data.length; i++) {
      if (unit) {
        break;
      }
      if (graph_data[i].unit) {
        unit = graph_data[i].unit;
      }
    }

    // 騒音や振動は20分 ~ 3時間の取得期間では1分間データに間引く
    if (cycle === 1 && diff_hour > 0.33 && diff_hour <= 3) {
      graph_data = this.getEvery1MinutesData(params, graph_data);
    }
    // 欠損値をnullで埋める
    const null_paddinged_graph_data = this.getNullPaddingedGraphData(
      params,
      diff_hour,
      graph_data
    );
    graph_data = null_paddinged_graph_data;

    const yAxis_domain = this.getYaxisDomain(
      min_value,
      max_value,
      min_of_graph_range,
      max_of_graph_range,
      value_to_change_graph_range
    );
    const yAxis_tick_span = this.getYaxisTickSpan(yAxis_domain);
    const yAxis_ticks = this.getYaxisTicks(yAxis_domain, yAxis_tick_span);
    const xAxis_params = this.getXaxisParams(diff_hour, cycle);
    const margin = {
      top: 30,
      right: diff_hour < 0.06 ? 40 : 50,
      bottom: 20,
      left: 15,
    };

    return (
      <React.Fragment>
        <div className={styles.chart_wrapper}>
          {<h2>{params.name}</h2>}
          <div className={styles.yAxis_unit_wrapper}>
            <div className={styles.yAxis_unit}>
              {unit ? `[ ${unit} ]` : null}
            </div>
          </div>
          <ResponsiveContainer>
            <LineChart data={graph_data} margin={margin}>
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Line
                dataKey="value"
                stroke="#8884d8"
                dot={false}
                unit={unit}
                connectNaN
                strokeWidth={2}
              />
              <XAxis
                dataKey="date"
                interval={xAxis_params.interval}
                height={xAxis_params.height}
                dy={xAxis_params.dy}
                angle={xAxis_params.angle}
                tickFormatter={xAxis_params.tickFormatter}
              />
              <YAxis domain={yAxis_domain} ticks={yAxis_ticks} interval={0} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </React.Fragment>
    );
  }
}

export default LineChartComponent;
